import React, { useState, useEffect } from 'react';
import ImageRepeater from './../components/ImageRepeater';
import ApiUrl from './../../../services/ApiUrl';
import axios from 'axios';
import Loader from './../components/loader/Loader';

const Funding = () => {
    let [ newInputs, setNewInput ] = useState([]);
    let [ valueTextPl, setValueTextPl ] = useState(''); 
    let [ valueTextEn, setValueTextEn ] = useState(''); 
    let [ valueTextDe, setValueTextDe ] = useState(''); 
    let [ load, setLoad ] = useState(0);
    let [ loader, setLoader ] = useState(1);

    useEffect(() => {
        if (load == 0) {
            ;(async function() {
                await setLoader(1);
                await getData();
                await setLoader(0);
                await setLoad(1);
            })()
        }
    },[load]);

    const getData = async () => {
        await axios({
            method: 'get',
            url: `${ApiUrl()}/showFounding`,
            headers: {
                'Authorization' : 'Bearer ' + `${localStorage.getItem('token')};${localStorage.getItem('userId')}`,
            },
        }).then(response => {
            let res = JSON.parse(response.request.response);
            console.log(res);
            // setNewInput(res[0].img_repeater)
            // setValueTextPl(res[1].text_pl[0].value);
            // setValueTextEn(res[2].text_en[0].value);
            // setValueTextDe(res[3].text_de[0].value);
            setValueTextPl(res[0].text_pl[0].value);
            setValueTextEn(res[1].text_en[0].value);
            setValueTextDe(res[2].text_de[0].value);
        });
    }

    const saveNewInputs = () => {
        setLoader(1);
        axios({
            method: 'post',
            url: `${ApiUrl()}/addNewFunding`,
            config: { headers: {'Content-Type': 'multipart/form-data' }},
            headers: {
                'Authorization' : 'Bearer ' + `${localStorage.getItem('token')};${localStorage.getItem('userId')}`,
            },
            data: {
                // inputs: newInputs,
                text_pl: valueTextPl,
                text_en: valueTextEn,
                text_de: valueTextDe,
            }
        }).then(response => {
            // let res = JSON.parse(response.request.response);
            setNewInput([]);
            setLoad(0);
        }).catch(e => {
            setNewInput([]);
            setLoad(0);
        });
    }

    return (
        <div className="container founding">
            <Loader loader={loader} />
            <div className="editor">
                <div className="header">
                    <h2>Treść strony (PL/EN/DE)</h2>
                </div>
                <textarea value={valueTextPl} onInput={(e) => setValueTextPl(e.target.value)}></textarea>
                <textarea value={valueTextEn} onInput={(e) => setValueTextEn(e.target.value)}></textarea>
                <textarea value={valueTextDe} onInput={(e) => setValueTextDe(e.target.value)}></textarea>
                {/* <div className="repeaters">
                {newInputs.map((item, i) => (
                    <ImageRepeater data={item} number={i} showNewInputs={newInputs} setNewInputs={ (newObject) => {setNewInput(newObject); console.log(newObject)} } />
                ))}
                </div> */}
                <div className="inputs-wrapper">
                    <div className="btn-box">
                        {/* <div className="btn" onClick={ () => { setNewInput([...newInputs, '']); } }>
                            <i className="material-icons">add</i>
                            Dodaj kolejne zdjęcie
                        </div> */}
                        <div className="btn" onClick={ () => saveNewInputs() }>
                            <i className="material-icons">save</i>
                            Zapisz zmiany
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Funding;
