import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from 'axios';
import ApiUrl from '../../../../services/ApiUrl';
// import CustomPagination from './customPagination';

let results = [];
export default class LocalsTable extends Component {
    async componentDidMount() {
      if(results.length === 0) {
        await this.props.checkTableFunction(1);
        await this.getLocals();
        await this.props.checkTableFunction(0);
      }else{
        this.setState({
          locals: results
        })
      } 
    }
    constructor(props) {
      super(props)

      this.state = {
        locals: [],
      }
    }

    async getLocals() {
      await axios({
          method: 'post',
          url: `${ApiUrl()}/localsList`,
          data: {
            userID: localStorage.getItem('userId'),
            token: localStorage.getItem('token'),
          }
      }).then(response => {
          results = JSON.parse(response.request.response);
          console.log(response);
      });

      results.map(local => {
        this.setState(prevState => ({
          locals: [...prevState.locals, { 
            unique_number             : local['unique_number'],
            lp                        : local['lp'],
            unit                      : local['unit'],
            precinct                  : local['precinct'],
            idencyficator             : local['idencyficator'],
            street                    : local['street'],
            number_building           : local['number_building'],
            type_local                : local['type_local'],
            surface_rooms             : local['surface_rooms'],
            count_chamber             : local['count_chamber'],
            room_area                 : local['room_area'],
            type_object               : local['type_object'],
            location                  : local['location'],
            entry_location            : local['entry_location'],
            parking                   : local['parking'],
            parking_bike              : local['parking_bike'],
            public_transport          : local['public_transport'],
            place_disabled_people     : local['place_disabled_people'],
            facilities_disabled       : local['facilities_disabled'],
            status                    : local['status'],
            character_recent_activity : local['character_recent_activity'],
            trade                     : local['trade'],
            landlord                  : local['landlord'],
            total_area_premises       : local['total_area_premises'],
            amount_rent               : local['amount_rent'],
            legal_status              : local['legal_status'],
            access_media              : local['access_media'],
            technical_condition       : local['technical_condition'],
            program                   : local['program'],
            contact                   : local['contact'],
            email                     : local['email'],
            www                       : local['www'],
            opening_hours             : local['opening_hours'],
            delivery_service          : local['delivery_service'],
            language                  : local['language'],
            created_at                : local['created_at'],
          }]
        }))
      })
    }

    render() {
        const columns = [
        {
          Header: 'Unikalna liczba',
          accessor: 'unique_number'
        },
        {
          Header: 'Lp',
          accessor: 'lp',
        },
        {
          Header: 'Jednostka',
          accessor: 'unit',
        },
        {
          Header: 'Obręb',
          accessor: 'precinct',
        },
        {
          Header: 'Identyfikator',
          accessor: 'idencyficator',
        },
        {
          Header: 'Ulica',
          accessor: 'street',
        },
        {
          Header: 'Numer lokalu',
          accessor: 'number_building',
        },
        {
          Header: 'Typ lokalu',
          accessor: 'type_local',
        },
        {
          Header: 'Powierzchnia użytkowa',
          accessor: 'surface_rooms',
        },
        {
          Header: 'Liczba izb',
          accessor: 'count_chamber',
        },
        {
          Header: 'Powierzchnia pomieszczeń',
          accessor: 'room_area',
        },
        {
          Header: 'Rodzaj obiektu',
          accessor: 'type_object',
        },
        {
          Header: 'Umiejscowienie',
          accessor: 'location',
        },
        {
          Header: 'Lokalizacja wejścia',
          accessor: 'entry_location',
        },
        {
          Header: 'Parking',
          accessor: 'parking',
        },
        {
          Header: 'Parking dla rowerów',
          accessor: 'parking_bike',
        },
        {
          Header: 'Komunikacja publiczna',
          accessor: 'public_transport',
        },
        {
          Header: 'lokal dla osób niepełnosprawnych',
          accessor: 'place_disabled_people',
        },
        {
          Header: 'Udogodnienia dla osób niepełnosprawnych',
          accessor: 'facilities_disabled',
        },
        {
          Header: 'Zajęty/Pustostan',
          accessor: 'status',
        },
        {
          Header: 'Charakter ostatniej działalności',
          accessor: 'character_recent_activity',
        },
        {
          Header: 'Branża',
          accessor: 'trade',
        },
        {
          Header: 'Właściciel lokalu',
          accessor: 'landlord',
        },
        {
          Header: 'Całkowita powierzchnia lokalu',
          accessor: 'total_area_premises',
        },
        {
          Header: 'Wysokość czynszu',
          accessor: 'amount_rent',
        },
        {
          Header: 'Stan prawny',
          accessor: 'legal_status',
        },

        {
          Header: 'Dostęp do mediów',
          accessor: 'access_media',
        },
        {
          Header: 'Stan techniczny',
          accessor: 'technical_condition',
        },
        {
          Header: 'Program',
          accessor: 'program',
        },
        {
          Header: 'Kontakt',
          accessor: 'contact',
        },
        {
          Header: 'Email',
          accessor: 'email',
        },
        {
          Header: 'WWW',
          accessor: 'www',
        },
        {
          Header: 'Godziny otwarcia',
          accessor: 'opening_hours',
        },
        {
          Header: 'Obsługa dostaw',
          accessor: 'delivery_service',
        },
        {
          Header: 'Język',
          accessor: 'language',
        },
      ]

        return (
          <div className={`column col-${ this.props.col }`}>
            <ReactTable
              data={this.state.locals}
              columns={columns}
              sortable={false}
              showPageSizeOptions={false}
              defaultPageSize={20}
              showPagination={true}
              filterable={true}
            />
            {/* <CustomPagination /> */}
          </div>
        )
    }
}