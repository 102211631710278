import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ApiUrl from './../../../services/ApiUrl';
import Input from '../partials/Input';
import Message from '../partials/Message';
import stringValidation from '../../../functions/validations/stringValidation';
import emailValidation from '../../../functions/validations/emailValidation';
import passwordValidation from '../../../functions/validations/passwordValidation';
import axios from 'axios';
import getString from './../../../strings/getString';
import countResultTable from './../../../functions/validations/countResultTable';

export default class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMain: [],
            errorEmail: [],
            errorPassword: [],
            errorLogin: [],
            errorFname: [],
            errorLname: [],

            login: '',
            email: '',
            password: '',
            secondPassword: '',
            firstName: '',
            LastName: '',
        }
    }
    clearRegisterErrors() {
        this.setState({
            errorEmail: [],
            errorPassword: [],
            errorLogin: [],
            errorFname: [],
            errorLname: [],
            errorMain: [],
        })
    }
    async handleSubmit(e) {
        e.preventDefault();
        await this.clearRegisterErrors();
        // login
        var dataLogin = {
            stringName: 'Login',
            checkString: this.state.login,
            minLength: 6,
            maxLength: 20,
        }
        const loginStringResult = stringValidation(dataLogin);

        const checkCountRecords = {
            stringName: 'Login',
            tableName: 'users',
            tableField: 'login',
            checkString: this.state.login,
        }

        await countResultTable(checkCountRecords).then(data => {
            if(data.length > 0) {
                this.setState((prevState) => ({
                    errorLogin: [...prevState.errorLogin, data]
                }))
            }
        })
        // email
        var dataEmail = {
            emailName: 'Email',
            checkEmail: this.state.email,
        }
        const emailCheck = emailValidation(dataEmail);

        const checkCountEmail = {
            stringName: 'E-mail',
            tableName: 'users',
            tableField: 'email',
            checkString: this.state.email,
        }

        await countResultTable(checkCountEmail).then(data => {
            if(data.length > 0) {
                this.setState((prevState) => ({
                    errorEmail: [...prevState.errorEmail, data]
                }))
            }
        })

        // password
        var dataPassword = {
            checkPassword_one: this.state.password,
            checkPassword_two: this.state.secondPassword,
            minLength: 6,
            maxLength: 30
        }
        const passwordCheck = passwordValidation(dataPassword);

        if ( loginStringResult && loginStringResult.length > 0 ) {
        
            loginStringResult.map(el => {
                this.setState({
                    errorLogin: [...this.state.errorLogin, el],
                })
            })
            
        }

        if ( emailCheck && emailCheck.length > 0 ) {
        
            emailCheck.map(el => {
                this.setState({
                    errorEmail: [...this.state.errorEmail, el],
                })
            })
            
        }

        if ( passwordCheck && passwordCheck.length > 0 ) {
        
            passwordCheck.map(el => {
                this.setState({
                    errorPassword: [...this.state.errorPassword, el],
                })
            })
            
        }

        if( this.state.errorLogin.length === 0 && this.state.errorEmail.length === 0 && this.state.errorPassword.length === 0 && this.state.errorMain.length === 0 ) {
            axios({
                method: 'post',
                url: `${ApiUrl()}/register`,
                data: {
                    name: this.state.firstName,
                    surname: this.state.LastName,
                    login: this.state.login,
                    password: this.state.password,
                    c_password: this.state.password,
                    email: this.state.email,
                }
            }).then(response => {
                let res = JSON.parse(response.request.response);
                console.log(res.status);
                if(res.status == 1) {
                    this.props.history.push("/login?register=success");
                }else{
                    this.setState({
                        errorMain: [...this.state.errorMain, getString('badValidate')]
                    })
                }
            }).catch(e => {
                this.setState({
                    errorMain: [...this.state.errorMain, getString('serverProblem')]
                })
            })
        }

    }
    render() {
        return (
            <>
                {this.state.errorMain &&
                    <Message info={this.state.errorMain} class="error" />}

                <h1>Zarejestruj się</h1>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <Input
                        name="login"
                        text="Wpisz login"
                        type="text"
                        placeholder="Login"
                        required={true}
                        errors={this.state.errorLogin}
                        handle={val => this.setState({ login: val })} />

                    <Input
                        name="email"
                        text="adres email"
                        type="email"
                        placeholder="E-mail"
                        required={true}
                        errors={this.state.errorEmail}
                        handle={val => this.setState({ email: val })} />


                    <Input
                        name="first-name"
                        text="Wpisz imię"
                        type="text"
                        placeholder="Imię"
                        required={false}
                        errors={this.state.errorFname}
                        handle={val => this.setState({ firstName: val })} />

                    <Input
                        name="last-name"
                        text="Wpisz nazwisko"
                        type="text"
                        placeholder="Nazwisko"
                        required={false}
                        errors={this.state.errorLname}
                        handle={val => this.setState({ LastName: val })} />

                    <Input
                        name="password"
                        text="Wpisz hasło"
                        type="password"
                        placeholder="Password"
                        required={true}
                        errors={this.state.errorPassword}
                        change='handlePassword'
                        handle={val => this.setState({ password: val })} />

                    <Input
                        name="password-second"
                        text="Powtórz hasło"
                        type="password"
                        placeholder="Password"
                        required={true}
                        errors={this.state.errorPassword}
                        change='handleSecondPassword'
                        handle={val => this.setState({ secondPassword: val })} />

                    <button type="submit">Załóż konto</button>
                </form>

                <div className="navigation">

                    <small>Powrót do strony <Link to="/login">logowania</Link></small>

                </div>
            </>
        );
    }
}