import React, { useEffect } from 'react';
import { Link } from "react-router-dom"
import logo from '../../../img/logo-nd.png';

const Navbar = () => {
    let toggleMenu = () => {
        document.body.classList.toggle('menu-toggle');
    }
    useEffect(() => {
        if (window.matchMedia("(max-width: 576px)").matches) {
            document.body.classList.toggle('menu-toggle');
          } else {
            document.body.classList.remove('menu-toggle');
          }
    })

    return (
        <nav className="menu" id="menu">
            <div className="menu-wrapper">
                <button id="toggle-menu" className="slide-menu" onClick={ () => toggleMenu() }><i className="material-icons">transit_enterexit</i></button>
                <h1>
                    <img src={ logo } alt="Norddigital" />
                    <small>Norddigital CMS</small>
                </h1>
                <ul>
                    <li>
                        <Link to="/dashboard">
                            <i className="material-icons">dashboard</i>
                            <span>Strona główna</span>
                        </Link>
                    </li>
                    
                    <li>
                        <Link to="/dashboard/users">
                            <i className="material-icons">people</i>
                            <span>Użytkownicy</span>
                        </Link>
                        <ul>
                            <li>
                                <Link to="/dashboard/users/new">
                                    <i className="material-icons">add</i>
                                    <span>Nowy</span>
                                </Link> 
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/dashboard/locals">
                            <i className="material-icons">house</i>
                            <span>Lokale</span>
                        </Link>
                        <ul>
                            <li>
                                <Link to="/dashboard/locals/import">
                                    <i className="material-icons">add</i>
                                    <span>Import xlsx</span>
                                </Link> 
                            </li>
                            <li>
                                <Link to="/dashboard/locals/trade">
                                    <i className="material-icons">category</i>
                                    <span>Branże</span>
                                </Link> 
                                <ul>
                                    <li>
                                        <Link to="/dashboard/locals/trade/new">
                                            <i className="material-icons">add</i>
                                            <span>Dodaj</span>
                                        </Link> 
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/dashboard/slider">
                            <i className="material-icons">slideshow</i>
                            <span>Slider</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/dashboard/funding">
                            <i className="material-icons">attach_money</i>
                            <span>Finansowanie</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/dashboard/translate">
                            <i className="material-icons">g_translate</i>
                            <span>Tłumaczenia</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/dashboard/reviews">
                            <i className="material-icons">star_half</i>
                            <span>Sprawdź opinie</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/dashboard/push">
                            <i className="material-icons">message</i>
                            <span>Wyślij wiadomość PUSH</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/dashboard/map-settings">
                            <i className="material-icons">settings_applications</i>
                            <span>Ustawienia mapy</span>
                        </Link>
                    </li>
                </ul>
                <a href="http://norddigital.com" className="description" alt="NordDigital" target="_blank">
                    <img src={ logo } />
                    <span> Created by NordDigital</span>
                </a>
            </div>
            <div className="menu-bg"></div>
        </nav>
    );
}

export default Navbar;
