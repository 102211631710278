import React, { useState,useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import '../../scss/dashboard.scss';
import Header from './partials/Header'
import Navbar from './partials/Navbar'
import Welcome from './pages/Welcome';
import Users from './pages/Users';
import User from './pages/User';
import MessagePush from './pages/MessagePush';
import Locals from './pages/Locals';
import ImportLocals from './pages/ImportLocals';
import Translate from './pages/Translate';
import Reviews from './pages/Reviews';
import Slider from './pages/Slider';
import Trade from './pages/Trade';
import newTrade from './pages/newTrade.jsx';
import Funding from './pages/Funding.jsx';
import MapSettings from './pages/MapSettings.jsx';
import ApiUrl from './../../services/ApiUrl';
import axios from 'axios';
// import { useSelector, useDispatch } from 'react-redux'

let userData = [];
const DashboardView = (props) => {
    // let userDataRedux = useSelector(state => state.onStart);

    // useEffect(() => {
    //     setFetch(1);
    // }, [userDataRedux])
    // const dispatch = useDispatch();
    let [load, setLoad] = useState(0);
    
    useEffect(() => {
        if (load == 0) {
            ;(async function() {
                await sessionUser();
                await getUser();
                await setLoad(1);
            })()
        }
    },[load]);

    const sessionUser = async () => {
        var currentUser = localStorage.getItem('token') ? true : false;
        if (!currentUser) {
            props.history.push({ pathname: '/login' });
        }
    }

    const getUser = async () => {
        await axios({
            method: 'post',
            url: `${ApiUrl()}/userById`,
            data: {
                userID: localStorage.getItem('userId'),
                token: localStorage.getItem('token'),
            }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            userData = res;
            // dispatch({ type: 'USER_DATA', value: userData })
        });
    }

    return (
        <>
            <div className="wrapper">
                <Navbar />
                <main>
                    <Header props={props} user={userData} />
                    <Switch>
                        <Route path={`/dashboard`} exact component={ Welcome } />
                        <Route path={`/dashboard/users`} exact component={ Users } />
                        <Route path={`/dashboard/users/:id`} component={ User } />
                        <Route path={`/dashboard/locals`} exact component={ Locals } />
                        <Route path={`/dashboard/locals/import`} exact component={ ImportLocals } />
                        <Route path={`/dashboard/locals/trade`} exact component={ Trade } />
                        <Route path={`/dashboard/locals/trade/new`} exact component={ newTrade } />
                        <Route path={`/dashboard/slider`} exact component={ Slider } />
                        <Route path={`/dashboard/funding`} exact component={ Funding } />
                        <Route path={`/dashboard/translate`} exact component={ Translate } />
                        <Route path={`/dashboard/reviews`} exact component={ Reviews } />
                        <Route path={`/dashboard/push`} exact component={ MessagePush } />
                        <Route path={`/dashboard/map-settings`} exact component={ MapSettings } />
                    </Switch>
                </main>
            </div>
        </>
    );
}

export default DashboardView;