import React, { useState, useEffect } from 'react'

const NewSliderInput = ( props ) => {
    let [ headerPl, setHeaderPl ] = useState();
    let [ headerEn, setHeaderEn ] = useState();
    let [ headerDe, setHeaderDe ] = useState();
    let [ descriptionPl, setDesciptionPl ] = useState();
    let [ descriptionEn, setDesciptionEn ] = useState();
    let [ descriptionDe, setDesciptionDe ] = useState();
    let newArray = [];
    let [ load, setLoad ] = useState(0);

    useEffect(() => {
        if(load === 0) {
            setHeaderPl(props.data.headerPl ? props.data.headerPl : '');
            setHeaderEn(props.data.headerEn ? props.data.headerEn : '');
            setHeaderDe(props.data.headerDe ? props.data.headerDe : '');
            setDesciptionPl(props.data.descriptionPl ? props.data.descriptionPl : '');
            setDesciptionEn(props.data.descriptionEn ? props.data.descriptionEn : '');
            setDesciptionDe(props.data.descriptionDe ? props.data.descriptionDe : '');
            setLoad(1)
        }
    }, [load])

    const createObject = () => {
        newArray = props.showNewInputs;
        newArray[props.number] = {                    
            headerPl: headerPl,
            headerEn: headerEn,
            headerDe: headerDe,
            descriptionPl: descriptionPl,
            descriptionEn: descriptionEn,
            descriptionDe: descriptionDe,
            id: props.data.id ? props.data.id : 0,
        }
        props.setNewInputs(newArray);
    }
    
    return (
        <div className="input-wrapper">
            <div className="number">
                <span>{ props.number +1 }</span>
            </div>
            <div className="box">
                <div className="item">
                    <label>Nagłówek PL</label>
                    <input type="text" onBlur={ () => createObject() } onChange={ (e) => setHeaderPl(e.target.value) } value={ headerPl } />
                </div>
                <div className="item">
                    <label>Nagłówek EN</label>
                    <input type="text" onBlur={ () => createObject() } onChange={ (e) => setHeaderEn(e.target.value) } value={ headerEn } />
                </div>
                <div className="item">
                    <label>Nagłówek DE</label>
                    <input type="text" onBlur={ () => createObject() } onChange={ (e) => setHeaderDe(e.target.value) } value={ headerDe } />
                </div>
            </div>
            <div className="box">
                <div className="item">
                    <label>Tekst PL</label>
                    <input type="text" onBlur={ () => createObject() } onChange={ (e) => setDesciptionPl(e.target.value) } value={ descriptionPl } />
                </div>
                <div className="item">
                    <label>Tekst EN</label>
                    <input type="text" onBlur={ () => createObject() } onChange={ (e) => setDesciptionEn(e.target.value) } value={ descriptionEn } />
                </div>
                <div className="item">
                    <label>Tekst DE</label>
                    <input type="text" onBlur={ () => createObject() } onChange={ (e) => setDesciptionDe(e.target.value) } value={ descriptionDe } />
                </div>
            </div>
        </div> 
    );
}

export default NewSliderInput;
