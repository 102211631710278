import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';
import ExcelFile from './../../../example_excel/lokale.xlsx';
import Loader from './../components/loader/Loader';

const ImportLocals = () => {
    let [ uploadExcel, setUploadExcel ] = useState([]);
    let [ loader, setLoader ] = useState(0);

    const sendExcel = () => {
        setLoader(1);
        let fData = new FormData();

        fData.append('file', uploadExcel);
        fData.append('userId', localStorage.getItem('userId'));
        fData.append('token', localStorage.getItem('token'))
        axios({
            method: 'post',
            url: `${ApiUrl()}/import`,
            config: { headers: {'Content-Type': 'multipart/form-data' }},
            data: fData
        }).then(response => {
            let res = JSON.parse(response.request.response);
            if(res.status == 1) {
                window.location.href = "/dashboard?status=addExport";
            }else{
                window.location.href = "/dashboard?status=access";
            }
        }).catch(e => {
            window.location.href = "/dashboard?status=problem";
        })
    }
    return (
        <>  
            <Loader loader={loader} />
            <div className="section-back">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xs-12">
                            <Link to="/dashboard/locals">Powrót</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card card-data">
                            <div className="card-header">
                                <div className="card-title card-over">
                                    <h5>Wgraj nowe lokale </h5>
                                </div>
                            </div>
                            <div className="card-body card-box">
                                <form className="upload-locals">
                                    <div class="input-file-container size-btn">  
                                        <input class="input-file" id="my-file" type="file" onChange={ (e) => setUploadExcel(e.target.files[0]) } />
                                        <label tabindex="0" for="my-file" class="input-file-trigger">{uploadExcel.name ? uploadExcel.name : "Wybierz plik xlsx"}</label>
                                    </div>
                                    <div className="size-btn">
                                        <div className={uploadExcel.name ? "btn" : "btn disable"} onClick={ () => uploadExcel.name && sendExcel() }>
                                            <i className="material-icons">add</i>
                                            Dodaj
                                        </div>
                                    </div>
                                </form>
                                <div className="info">
                                    <h2>Zasady</h2>
                                    <div className="pkt">
                                        <span>1.</span>
                                        <p>Plik z rozszerzeniem .xlsx</p>
                                    </div>
                                    <div className="pkt">
                                        <span>2.</span>
                                        <p>Plik musi posiadać identyczną strukturę jak ten <a href={ExcelFile} download>tutaj</a></p>
                                    </div>
                                    <div className="pkt">
                                        <span>3.</span>
                                        <p>Lokale są w pełni zarządzane przez plik xlsx</p>
                                    </div>
                                    <div className="pkt">
                                        <span>4.</span>
                                        <p>Jeśli chcemy coś dodać/usunąć/zmienić należy wgrać ponownie zmieniony plik xlsx</p>
                                    </div>
                                    <div className="pkt">
                                        <span>5.</span>
                                        <p>Należy zawsze wgrywać aktualny plik excela ( z zawartością, która już jest wgrana), ponieważ starsze dane są usuwane podczas wprowadzania nowych, aby zawsze były zgodne z excelem</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </>        
    );
}

export default ImportLocals;
