import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from 'axios';
import ApiUrl from '../../../../services/ApiUrl';

let results = [];
export default class UsersTable extends Component {
    async componentDidMount() {
      this.getUsers();
    }
    constructor(props) {
      super(props)

      this.state = {
        users: [],
        removeUserWindow: 0,
        removeUser: [],
      }
    }

    async getUsers() {
      await axios({
          method: 'post',
          url: `${ApiUrl()}/usersList`,
          // headers: {
          //     'Accept': 'application/json',
          //     'Content-Type': 'application/json',
          //     'Access-Control-Allow-Origin': '*'
          // },
          data: {
            userID: localStorage.getItem('userId'),
            token: localStorage.getItem('token'),
          }
      }).then(response => {
          results = JSON.parse(response.request.response);
      });

      results.map(user => {
        this.setState(prevState => ({
          users: [...prevState.users, { 
            login         : user['login'],
            email         : user['email'],
            firstName     : user['name'],
            lastName      : user['surname'],
            createdAt     : user['created_at'],
            userId        : user['userId'],
          }]
        }))
      })
    }

    render() {

        const removeUser = () => {
          axios({
            method: 'post',
            url: `${ApiUrl()}/removeUser`,
            header: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: {
              userID: localStorage.getItem('userId'),
              token: localStorage.getItem('token'),
              removeUserId: this.state.removeUser.original.userId
            }
          }).then(response => {
              results = JSON.parse(response.request.response);
              if(results.status == 1) {
                this.props.This.history.push('/dashboard/users?status=removeUser');
              }else{
                this.props.This.history.push('/dashboard/users?status=problem');
              }
              let newUsers = this.state.users;
              newUsers.splice(this.state.removeUser.index, 1);
              this.setState({
                users: newUsers
              })
              this.setState({
                removeUserWindow: 0,
                removeUser: [],
              })
          });
        }
       
        const columns = [
        {
          Header: 'Login',
          accessor: 'login'
        },
        {
          Header: 'Email',
          accessor: 'email',
        },
        {
          Header: 'Imię',
          accessor: 'firstName',
        },
        {
          Header: 'Nazwisko',
          accessor: 'lastName',
        },
        {
          Header: 'Utworzono',
          accessor: 'createdAt',
        },
        {
          Header: 'Akcje',
          Cell: props => 
          <div className="actions">
            {props.original.userId != localStorage.getItem('userId') &&
              <div className="ico error" onClick={ () => this.setState({removeUserWindow: 1, removeUser: props}) }>
                <i className="material-icons">delete</i>
              </div>
            }
            { this.state.removeUserWindow ? 
              <div className="modal">
                <h2>OSTRZEŻENIE</h2>
                <div className="icon">
                  <i className="material-icons">error_outline</i>
                </div> 
                <div className="exit" onClick={ () => this.setState({removeUserWindow: 0, removeUser: []}) }>
                  <i className="material-icons">highlight_off</i>
                </div>
                <p>Czy aby na pewno chcesz usunąć administratora <strong>{this.state.removeUser.original.login}</strong> ?</p>
                <p>Akcji nie będzie można cofnąć!</p>
                <div className="btn-wrap">
                    <div className="col-sm-4">
                        <div className="btn" onClick={ () => removeUser() }>
                            <i className="material-icons">delete_outline</i>
                            Usuń
                        </div>
                    </div>
                </div>
              </div>
              :
              null
            } 
          </div>
        }
      ]

        return (
          <div className={`column col-${ this.props.col }`}>
            <ReactTable
              data={this.state.users}
              columns={columns}
            />
          </div>
          
        )
    }
}