import React from 'react'
import {Route, Redirect} from 'react-router-dom';
import redirectSession from './../functions/functions/redirectSession';

export const ProtectedRoute = ({component: Component, ...rest}) => {
    let token = localStorage.getItem('token');
    return (
        <Route 
            {...rest} 
            render= {props => {
                if(token && token.length > 0) {
                    redirectSession();
                    return <Component {...props} />
                }else{
                    return <Redirect to={
                        {
                            pathname: "/login",
                            state: {
                                from: props.location
                            }
                        }
                    } />
                }
            }}
        />
    )
}