import React, { useState, useEffect } from 'react';
import NewTranslateInput from '../components/NewTranslateInput';
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';
import Loader from './../components/loader/Loader';

const Translate = () => {
    let [ newInputs, setNewInput ] = useState([]);
    let [ load, setLoad ] = useState(0);
    let [ loader, setLoader ] = useState(1);

    useEffect(() => {
        if (load == 0) {
            ;(async function() {
                await setLoader(1);
                await getTranslate();
                await setLoader(0);
                await setLoad(1);
            })()
        }
    },[load]);

    const getTranslate = async () => {
        await axios({
            method: 'post',
            url: `${ApiUrl()}/showTranslate`,
            header: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: {
                userID: localStorage.getItem('userId'),
                token: localStorage.getItem('token'),
            }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            setNewInput(res)
        });
    }

    const saveNewInputs = () => {
        setLoader(1);
        axios({
            method: 'post',
            url: `${ApiUrl()}/addNewTranslate`,
            data: {
                userID: localStorage.getItem('userId'),
                token: localStorage.getItem('token'),
                inputs: newInputs,
            }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            setNewInput([]);
            setLoad(0);
        });
    }

    return (
        <div className="container">
            <Loader loader={loader} />
            <div className="inputs-wrapper">
                <div className="header">
                    <h2>Tłumaczenia</h2>
                </div>
                {newInputs.map((item, i) => (
                    <NewTranslateInput data={item} number={i} showNewInputs={newInputs} setNewInputs={ (newObject) => setNewInput(newObject) } />
                ))}
                <div className="btn-box">
                    <div className="btn" onClick={ () => { setNewInput([...newInputs, '']); } }>
                        <i className="material-icons">add</i>
                        Dodaj kolejne tłumaczenie
                    </div>
                    { newInputs.length > 0 &&
                        <div className="btn" onClick={ () => saveNewInputs() }>
                            <i className="material-icons">save</i>
                            Zapisz zmiany
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Translate;
