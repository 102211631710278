import React, { useEffect, useState } from 'react'
import Input from './../components/Input'
import axios from 'axios'
import ApiUrl from './../../../services/ApiUrl'

const MapSettings = (props) => {
    let [ load, setLoad ] = useState(0);
    let [ zoom, setZoom ] = useState('');
    let [ lat, setLat ] = useState('');
    let [ lng, setLng ] = useState('');

    let [ errorZoom, setErrorZoom ] = useState(0);
    let [ errorLat, setErrorLat ] = useState(0);
    let [ errorLng, setErrorLng ] = useState(0);

    useEffect(() => {
        if (load == 0) {
            ;(async function() {
                await getSettingsMap();             
                await setLoad(1);
            })()
        }
    },[load]);

    const getSettingsMap = async () => {
        await axios({
            method: 'get',
            url: `${ApiUrl()}/showMapSettings`,
            headers: {
                'Authorization' : 'Bearer ' + `${localStorage.getItem('token')};${localStorage.getItem('userId')}`,
            },
        }).then(response => {
            let res = JSON.parse(response.request.response);
            setZoom(res[0].zoom[0].value);
            setLat(res[1].lat[0].value)
            setLng(res[2].lng[0].value)
        });
    }

    const changeSettings = () => {
        let errors = 0;
        if(zoom.length == 0) {
            setErrorZoom(1);
            errors = 1;
        } 
        if(lat.length == 0) {
            setErrorLat(1);
            errors = 1;
        } 
        if(lng.length == 0) {
            setErrorLng(1);
            errors = 1;
        } 
        if(errors == 0) {
            axios({
                method: 'post',
                url: `${ApiUrl()}/changeMapSettings`,
                headers: {
                    'Authorization' : 'Bearer ' + `${localStorage.getItem('token')};${localStorage.getItem('userId')}`,
                },
                data: {
                    zoom: zoom,
                    lng: lng,
                    lat: lat
                }
            }).then(response => {
                let res = JSON.parse(response.request.response);
                if(res.status == "success") {
                    window.location.href = "/dashboard?status=changeSettingMap";
                }else{
                    window.location.href = "/dashboard?status=access";
                }

            }).catch(e => {
                window.location.href = "/dashboard?status=problem";
            })
        }
    }
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-xs-12">
                    <div className="card card-data">
                        <div className="card-header">
                            <div className="card-title card-over">
                                <h5>Ustawienia mapy </h5>
                            </div>
                        </div>
                        <div className="card-body">
                        <form>
                            <div className="row">
                                <div className="col-sm-9">
                                    <div className="row">
                                        <Input name="zoom"
                                            label="Zoom"
                                            type="number" 
                                            value={ zoom }
                                            disabled={ false } 
                                            handle={ (val) => setZoom(val) } 
                                            error={errorZoom}
                                            column="xs-12" />
                                    </div>
                                    <div className="row">
                                        <Input name="lat"
                                            label="Lat"
                                            type="number" 
                                            value={ lat }
                                            disabled={ false } 
                                            handle={ (val) => setLat(val) } 
                                            error={errorLat}
                                            column="xs-12" />
                                    </div>
                                    <div className="row">
                                        <Input name="lng"
                                            label="Lng"
                                            type="number" 
                                            value={ lng }
                                            disabled={ false } 
                                            handle={ (val) => setLng(val) } 
                                            error={errorLng}
                                            column="xs-12" />
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="btn" onClick={ () => changeSettings() } > Zmień </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </form>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MapSettings;
