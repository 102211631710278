import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from 'axios';
import ApiUrl from '../../../../services/ApiUrl';

let results = [];
export default class TradeTable extends Component {
    async componentDidMount() {
      this.getItems();
    }
    constructor(props) {
      super(props)

      this.state = {
        items: [],
        removeUserWindow: 0,
        removeUser: [],
      }
    }

    async getItems() {
      await axios({
          method: 'get',
          url: `${ApiUrl()}/tradeList`,
          headers: {
            'Authorization' : 'Bearer ' + `${localStorage.getItem('token')};${localStorage.getItem('userId')}`,
          },
      }).then(response => {
          results = JSON.parse(response.request.response);
          console.log(results);
      });

      results.map(item => {
        this.setState(prevState => ({
          items: [...prevState.items, { 
            ID                : item['legend_id'],
            PL                : item['legend_pl'],
            EN                : item['legend_en'],
            DE                : item['legend_de'],
            color             : item['color'],
            updated_at        : item['updated_at'],
            created_at        : item['created_at'],
          }]
        }))
      })
    }

    render() {
       
        const columns = [
        {
          Header: 'ID',
          accessor: 'ID'
        },
        {
          Header: 'PL',
          accessor: 'PL',
        },
        {
          Header: 'EN',
          accessor: 'EN',
        },
        {
          Header: 'DE',
          accessor: 'DE',
        },
        {
          Header: 'Kolor',
          accessor: 'color',
        },
        {
          Header: 'Zaaktualizowano',
          accessor: 'updated_at',
        },
        {
          Header: 'Utworzono',
          accessor: 'created_at',
        },
      ]

        return (
          <div className={`column col-${ this.props.col }`}>
            <ReactTable
              data={this.state.items}
              columns={columns}
            />
          </div>
          
        )
    }
}