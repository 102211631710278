import React, { useEffect, useState } from 'react'
import ReviewsTable from './../components/table/ReviewsTable'
import CardStats from './../components/CardStats'
import axios from 'axios'
import ApiUrl from './../../../services/ApiUrl'

let countReviews;

const Locals = (props) => {
    let [load, setLoad] = useState(0);
    
    useEffect(() => {
        if (load == 0 && !countReviews) {
            ;(async function() {
                await localsList();
                await setLoad(1);
            })()
        }
    },[load]);

    const localsList = async () => {
        await axios({
            method: 'get',
            url: `${ApiUrl()}/countReviews`,
        }).then(response => {
            countReviews = response.request.response;
        });
    }

    const goToPage = () => {
        props.history.push('/dashboard/locals/import')
    }
    return (
        <>  
            <div className="container-fluid">
                <div className="row">
                    <CardStats title="Wszystkich opinii" 
                                data={countReviews} 
                                icon="star_border"
                                desc={ false }
                                column="sm-3" />
                </div>
                <div className="row">
                    <ReviewsTable col="xs-12" This={props}/>
                </div>
            </div>
        </>        
    );
}

export default Locals;



