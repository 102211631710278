import React, { useState } from 'react';
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';

const MessagePush = () => {
    let [ valueMessage, setValueMessage ] = useState('');
    let [ valueHeader, setValueHeader ] = useState('');
    let [ messageTo, setMessageTo ] = useState('app');
    let [ valueLink, setValueLink ] = useState();
    let [ image, setImage ] = useState();

    const saveMessage = () => {
        if(valueMessage.length > 0 && valueHeader.length > 0) {
            let fData = new FormData();
            fData.append('image', image);
            fData.append('valueHeader', valueHeader);
            fData.append('valueMessage', valueMessage);
            fData.append('valueLink', valueLink);
            fData.append('messageTo', messageTo);

            axios({
                method: 'post',
                url: `${ApiUrl()}/sendNotification`,
                headers: {
                    'Authorization' : 'Bearer ' + `${localStorage.getItem('token')};${localStorage.getItem('userId')}`,
                    'Content-Type': 'multipart/form-data',
                },
                data: fData,
                // data: {
                //     valueMessage: valueMessage,
                //     valueHeader: valueHeader,
                //     valueLink: valueLink,
                //     messageTo: messageTo,
                //     image: fData
                // }
            }).then(response => {
                let res = JSON.parse(response.request.response);
                if(res.status == "success") {
                    window.location.href = "/dashboard?status=messagePush";
                }else{
                    window.location.href = "/dashboard?status=access";
                }
            }).catch(e => {
                window.location.href = "/dashboard?status=problem";
            })
        }
    }
    return (
        <div className="container">
            <div className="editor">
                <div className="header">
                    <h2>Wiadomość</h2>
                </div>
                {/* <select onChange={(e) => setMessageTo(e.target.value)}>
                    <option value="all">Wszystkie urządzenia</option>
                    <option value="ios">IOS</option>
                    <option value="android">ANDROID</option>
                </select> */}
                <input placeholder="nagłówek" type="text" onInput={(e) => setValueHeader(e.target.value)} value={valueHeader} />
                {/* <input placeholder="link po kliknięciu" type="text" onInput={(e) => setValueLink(e.target.value)} value={valueLink} /> */}
                <input className="imageInput" type="file" onChange={ (e) => setImage(e.target.files[0]) } />
                <textarea placeholder="treść wiadomości" onInput={(e) => setValueMessage(e.target.value)} value={valueMessage} required></textarea>
                <div className="btn-wrap-send">
                    <div className="col-sm-4">
                        <div className={valueMessage.length > 0 && valueHeader.length > 0 ? "btn" : "btn disable" } onClick={ () => saveMessage() }>
                            <i className="material-icons">done</i>
                            PUSH
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MessagePush;
