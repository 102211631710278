import React, { Component } from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from 'axios';
import ApiUrl from '../../../../services/ApiUrl';

let results = [];
export default class ReviewsTable extends Component {
    async componentDidMount() {
      this.getReviews();
    }
    constructor(props) {
      super(props)

      this.state = {
        reviews: [],
      }
    }

    async getReviews() {
      await axios({
          method: 'post',
          url: `${ApiUrl()}/reviewsList`,
          data: {
            userID: localStorage.getItem('userId'),
            token: localStorage.getItem('token'),
          }
      }).then(response => {
          results = JSON.parse(response.request.response);
      });

      results.map(review => {
        this.setState(prevState => ({
          reviews: [...prevState.reviews, { 
            deviceId           : review['deviceId'],
            stars              : review['stars'],
            opinion            : review['opinion'],
            created_at         : review['created_at'],
          }]
        }))
      })
    }

    render() {
       
        const columns = [
        {
          Header: 'Identyfikator urządzenia',
          accessor: 'deviceId'
        },
        {
          Header: 'Gwiazdki',
          accessor: 'stars',
        },
        {
          Header: 'Opinia',
          accessor: 'opinion',
        },
        {
          Header: 'Data utworzenia',
          accessor: 'created_at',
        },
      ]

        return (
          <div className={`column col-${ this.props.col }`}>
            <ReactTable
              data={this.state.reviews}
              columns={columns}
            />
          </div>
          
        )
    }
}