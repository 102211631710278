const ApiUrl = () => {

    let link = '';
    if ( window.location.hostname === 'localhost') {
        link = 'http://localhost/api'
    }
    if ( window.location.hostname === 'cms-slf.norddigital.com') {
        link = 'https://szczecin-lf.s1.norddigital.pl/api'
    }

    return link;
}

export default ApiUrl;