import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Input from './../components/Input'
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';
import { SketchPicker } from 'react-color';

const User = ({match}) => {
    let [ legendId, setLegendId ] = useState('');
    let [ legendPl, setLegendPl ] = useState('');
    let [ legendEn, setLegendEn ] = useState('');
    let [ legendDe, setLegendDe ] = useState('');
    let [ colorPicker, setColorPicker ] = useState('#ffffff');

    let [ errorLegendId, setErrorLegendId ] = useState(0);
    let [ errorLegendPl, setErrorLegendPl ] = useState(0);
    let [ errorLegendEn, setErrorLegendEn ] = useState(0);
    let [ errorLegendDe, setErrorLegendDe ] = useState(0);

    const clearErrors = () => {
        setErrorLegendId(0);
        setErrorLegendPl(0);
        setErrorLegendEn(0);
        setErrorLegendDe(0);
    }

    const createTrade = async() => {
        clearErrors();
        let errors = 0;

        if(!legendId && legendId.length == 0) {
            setErrorLegendId(1);
            errors = 1;   
        }

        if(!legendPl && legendPl.length == 0) {
            setErrorLegendPl(1);
            errors = 1;   
        }

        if(!legendEn && legendEn.length == 0) {
            setErrorLegendEn(1);
            errors = 1;   
        }

        if(!legendDe && legendDe.length == 0) {
            setErrorLegendDe(1);
            errors = 1;   
        }

        if(errors === 0) {
            axios({
                method: 'post',
                url: `${ApiUrl()}/newTrade`,
                headers: {
                    'Authorization' : 'Bearer ' + `${localStorage.getItem('token')};${localStorage.getItem('userId')}`,
                },
                data: {
                    legendId    : legendId,
                    legendPl    : legendPl,
                    legendEn    : legendEn,
                    legendDe    : legendDe,
                    color       : colorPicker,
                }
            }).then(response => {
                let res = JSON.parse(response.request.response);
                if(res.status == 'update') {
                    window.location.href = "/dashboard?status=updateTrade";
                }else if(res.status == 'create'){
                    window.location.href = "/dashboard?status=newTrade";
                }else{
                    window.location.href = "/dashboard?status=access";
                }
            }).catch(e => {
                window.location.href = "/dashboard?status=problem";
            })
        }
    }

    const getTrade = () => {
        if(legendId && legendId.length > 0) {
            axios({
                method: 'post',
                url: `${ApiUrl()}/getTradeById`,
                headers: {
                    'Authorization' : 'Bearer ' + `${localStorage.getItem('token')};${localStorage.getItem('userId')}`,
                },
                data: {
                    legendId    : legendId,
                }
            }).then(response => {
                let res = JSON.parse(response.request.response);
                if(res.status == true) {
                    console.log()
                    setLegendDe(res.data[0].legend_de);
                    setLegendEn(res.data[0].legend_en);
                    setLegendPl(res.data[0].legend_pl);
                    setColorPicker(res.data[0].color)
                }
            })
        }
    }

    return (
        <>  
            <div className="section-back">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xs-12">
                            <Link to="/dashboard/locals/trade">Powrót</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card card-data">
                            <div className="card-header">
                                <div className="card-title card-over">
                                    <h5>Dodaj/Zaaktualizuj branżę: </h5>
                                    <p>Jeśli wpiszesz istniejące ID to branża zostanie zaaktualizowana, w przeciwnym razie zostanie utworzona nowa. Jeśli klikniesz "pobierz dane" to zostaną pobrane dane wpisanego wcześniej ID do edycji (jeśli istnieje).</p>
                                </div>
                            </div>
                            <div className="card-body">
                            <form>
                                <div className="row">
                                    <div className="col-sm-12 box-wrapper">
                                        <div className="box">
                                            <div className="row">
                                                <Input name="legend-id"
                                                    label="ID (numer odpowiadający numerowi w excelu)"
                                                    type="number" 
                                                    value={ legendId }
                                                    disabled={ false } 
                                                    handle={ (val) => setLegendId(val) } 
                                                    error={errorLegendId}
                                                    column="xs-12" />
                                            </div>
                                            <div className="row">
                                                <Input name="legend-pl"
                                                    label="legenda (język polski)"
                                                    type="text" 
                                                    value={ legendPl }
                                                    disabled={ false } 
                                                    handle={ (val) => setLegendPl(val) } 
                                                    error={errorLegendPl}
                                                    column="xs-12" />
                                            </div>
                                            <div className="row">
                                                <Input name="legend-en"
                                                    label="legenda (język angielski)"
                                                    type="text" 
                                                    value={ legendEn }
                                                    disabled={ false } 
                                                    handle={ (val) => setLegendEn(val) } 
                                                    error={errorLegendEn}
                                                    column="xs-12" />
                                            </div>
                                            <div className="row">
                                                <Input name="legend-de"
                                                    label="legenda (język niemiecki)"
                                                    type="text" 
                                                    value={ legendDe }
                                                    disabled={ false } 
                                                    handle={ (val) => setLegendDe(val) } 
                                                    error={errorLegendDe}
                                                    column="xs-12" />
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="row color-picker">
                                                <SketchPicker width="250" color={colorPicker} onChange={ (click) => setColorPicker(click.hex) } />
                                                <p style={{color: colorPicker}}>{legendPl}</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-5 col-sm-10 col-xs-12">
                                                    <div className="btn" onClick={ () => createTrade() }>Wykonaj</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-5 col-sm-10 col-xs-12">
                                                    <div className="btn" onClick={ () => getTrade() }>Pobierz dane</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </>        
    );
}

export default User;
