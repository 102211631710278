import React, { useEffect, useState } from 'react'
import LocalsTable from './../components/table/LocalsTable'
import CardStats from './../components/CardStats'
import axios from 'axios'
import ApiUrl from './../../../services/ApiUrl'
import Loader from './../components/loader/Loader';

let countLocals;
const Locals = (props) => {
    let [load, setLoad] = useState(0);
    let [loader, setLoader] = useState(0);
    let [checkTable, setCheckTable] = useState(0);

    useEffect(() => {
        if (load == 0 && !countLocals) {
            ;(async function() {
                await localsList();
                await setLoad(1);
            })()
        }
    },[load]);

    useEffect(() => {
        console.log(checkTable);
        if(checkTable === 1) {
            setLoader(1);
        }
        if(checkTable === 0) {
            setLoader(0);
        }
    }, [checkTable])


    const localsList = async () => {
        await axios({
            method: 'get',
            url: `${ApiUrl()}/countLocals`,
        }).then(response => {
            countLocals = response.request.response;
        });
    }

    const goToPage = () => {
        props.history.push('/dashboard/locals/import')
    }
    return (
        <>  
            <Loader loader={loader} />
            <div className="container-fluid">
                <div className="row">
                    <CardStats title="Wszystkich lokali" 
                                data={countLocals} 
                                icon="home"
                                desc={ false }
                                column="sm-3" />
                </div>
                <div className="row">
                    <div className="col-sm-3">
                        <div className="btn" onClick={ () => goToPage() }>
                            <i className="material-icons">add</i>
                            Wgraj xlsx
                        </div>
                    </div>
                </div>
                <div className="row">
                    <LocalsTable col="xs-12" This={props} checkTableFunction={ (status) => setCheckTable(status) }/>
                </div>
            </div>
        </>        
    );
}

export default Locals;



